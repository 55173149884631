// 1.引入axios
import axios from "axios";

// //2.引入element ui中的message
import { Message, MessageBox } from 'element-ui'
// 引入路由实例
import router from '@/router'

// 创建axios
let serve = axios.create({
    // 设置接口前缀
    // baseURL: "https://store.chejiguanjia.com/",
    baseURL: "https://storeapi.chejihui.cn/",
    // baseURL: "http://localhost:8066/",
    // 设置超时时间
    timeout: 60000,
    // 设置请求头
    headers: {
        // 'Content-Type': 'multipart/form-data',
        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
        'ApShop-Session-Id': localStorage.getItem('ApShop-Session-Id'),

    }
})

// 定义一个变量 存路径
let path = "";

// 请求拦截器  发送请求的路上准备干点啥
serve.interceptors.request.use((config) => {
    // console.log(config, '接口信息');
    // if (config.baseURL == 'https://test2.chejiguanjia.com/') {
    //     config.url = "/dev-api" + config.url
    // }
    console.log('config', localStorage.getItem('ApShop-Session-Id'));
    // 如果没有session 且不是login页则跳转页面
    // if(!localStorage.getItem('ApShop-Session-Id') && router.history && router.history.current && router.history.current.path !== '/login'){
    //    return router.push("/login");
    // }

    config.headers['ApShop-Session-Id'] = localStorage.getItem('ApShop-Session-Id');
    // 除了登录和注册 其余的接口都需要配置token字段请求
    if (config.url != 'merchantAccount/login/submit') {
        // config.headers.ApShopSessionId ='6666';
    }
    if (config.method === 'img') {
        config.headers['Content-Type'] = 'multipart/form-data';
        config.method = 'post';
    }
    if (config.method === 'post_excel') {
        config.method = 'post';
        config.responseType = 'arraybuffer';
    }
    // 每次请求的时候都先将url赋值给path
    path = config.url
    return config;
})
let timerId = null;
// 响应拦截器  如果数据请求成功了 干点啥？
serve.interceptors.response.use((res) => {
    // console.log(res,'resssss')
    // 判断请求成功的状态码 如果是200，将data返回出去
    if (res.status === 200) {
        // const that = this
        // console.log(this,'this');
        if (res.data.error == 620010) {
            if (timerId) {
                clearTimeout(timerId);
            }
            console.log('过期');
            res = null

            timerId = setTimeout(() => {
                // 这里使用你的Message函数来显示消息
                Message({
                    message: '登录过期',
                    type: 'error'
                });
                // 清除定时器ID，确保下一次调用时可以重新设置
                timerId = null;
            }, 1000);
            setTimeout(() => {
                console.log(router,'routerrouter')
                if(router.history && router.history.current && router.history.current.path !== '/login'){
                    localStorage.removeItem("ApShop-Session-Id");
                    localStorage.removeItem("sign_login");
                    router.push("/login");
                }
            }, 1000);
        
          } 
        if (res.data.error == 0) {
            return res.data;
        } else {
            if(res.config.responseType && res.config.responseType === 'arraybuffer'){
                return res;
            }else {
                Message({
                    message: res.data.message,
                    type: 'error'
                });
                return res.data
            }

        }

    } else {
        console.log('响应拦截', res);
        // 如果是别的错误 直接弹出去
        Message({
            message: '请求失败',
            type: 'error'
        });
    }
})

// 封装post
export function postData(url, method, data) {
    return new Promise((resolve) => {
        serve({
            method,
            url,
            data
        })
            .then((res) => {
                resolve(res)
            })
            .catch(() => {
                // Message({
                //     message: '系统错误',
                //     type: 'error'
                // });
            })
    })
}
// 封装get
export function getData(url, method, data) {
    return new Promise((resolve) => {
        serve({
            method,
            url,
            params: data
        })
            .then((res) => {
                resolve(res)
            })
            .catch(() => {
                // Message({
                //     message: '系统错误',
                //     type: 'error'
                // });
            })
    })
}