import { render, staticRenderFns } from "./HomeLeft.vue?vue&type=template&id=3b8226a5&scoped=true"
import script from "./HomeLeft.vue?vue&type=script&lang=js"
export * from "./HomeLeft.vue?vue&type=script&lang=js"
import style0 from "./HomeLeft.vue?vue&type=style&index=0&id=3b8226a5&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3b8226a5",
  null
  
)

export default component.exports